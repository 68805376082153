import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable()
export class RefresherService {
    static routerSubscribe: Subscription;

    static lastRefreshTime: string;
    static lastRoute: string;

    static currComponent;

    constructor(private router: Router) {
        RefresherService.routerSubscribe = this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                if (RefresherService.lastRoute && RefresherService.lastRoute != val.url) {
                    this.hideAll();
                }
                RefresherService.lastRoute = val.url
            }
        });
    }

    ngOnDestroy() {
        RefresherService.routerSubscribe.unsubscribe();
    }

    isVisible() {
        return RefresherService.currComponent;
    }

    getLastRefreshTime() {
        return RefresherService.lastRefreshTime;
    }

    static setRefreshFlag(comp) {
        RefresherService.currComponent = comp;
        RefresherService.lastRefreshTime = new Date().toLocaleString();
    }

    static fireRefreshEvt() {
        if (RefresherService.currComponent) {
            RefresherService.currComponent.refreshFn();
            RefresherService.lastRefreshTime = new Date().toLocaleString();
        }
    }

    private hideAll() {
        RefresherService.lastRefreshTime = undefined;
        RefresherService.currComponent = undefined;
    }
}
