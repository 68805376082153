export const environment = {
    production: false,
    endPoint: 'https://dev-commswift-api.azurewebsites.net/',
    socketURL: 'wss://dev-wpp-vm.uaenorth.cloudapp.azure.com:3838',
    domain: 'https://dev.commswift.com/'
  }
  export const COUPON = [
    {
      id:'mGkOlFW6',
      name:'SAFE15'
    }
  ]
  export const PLANS_MONTH = [
    {
      name:"P1",
      id:"price_1MapuELjEwGMVpD1CgGkiMgC",
      price:0,
      additionalBot:0,
      backupNum:0,
      additionalUsers:1,
      additionalWA:1,
      Email2WA:0,

    },
    {
      name:"P2",
      id:"price_1MaprCLjEwGMVpD1Nesx225M",
      price:0,
      additionalBot:0,
      backupNum:0,
      additionalUsers:1,
      additionalWA:1,
      Email2WA:0,
    },
    {
      name:"P3",
      id:"price_1MH9JvLjEwGMVpD1NJQYMvj8",
      price:0,
      additionalBot:0,
      backupNum:0,
      additionalUsers:3,
      additionalWA:1,
      Email2WA:0,
    },
    {
      name:"P4",
      id:"price_1MH9JVLjEwGMVpD1i1Eu8ni0",
      price:0,
      additionalBot:0,
      backupNum:0,
      additionalUsers:5,
      additionalWA:1,
      Email2WA:0,
    },
    {
      name:"P5",
      id:"price_1MapyXLjEwGMVpD1tP4hYNkb",
      price:0,
      additionalBot:0,
      backupNum:0,
      additionalUsers:50,
      additionalWA:5,
      Email2WA:0,
    },
    {
      name:"P6",
      id:"price_1MX9aXLjEwGMVpD1BIYN5x6M",
      price:0,
      additionalBot:0,
      backupNum:0,
      additionalUsers:50,
      additionalWA:5,
      Email2WA:0,
    },
    {
      name:"additional5Users",
      Plan4_id:"price_1MYhrRLjEwGMVpD1NNjZeZQk",
      Plan5_id:"price_1MYhrQLjEwGMVpD1opvxf123",
      Plan6_id:"price_1MYhrRLjEwGMVpD1JrhqmwoC",
      price4:0,
      price5:0,
      price6:0
    },
    {
      name:"waNumbers",
      id:"price_1MYhseLjEwGMVpD1Z6EE3VhR",
      price:0
    },
    {
      name:"bots",
      id:"price_1Maq5LLjEwGMVpD1KSc9h5dG",
      price:0
    },
    {
      name:"Email2WA",
      id:"price_1Maq7ELjEwGMVpD11ibTpxDO",
      price:0
    },
    {
      name:"Backup",
      id:"price_1Maq8ZLjEwGMVpD16Je9gQI9",
      price:0
    },

  ]
  export const PLANS_YEAR = [
    {
      name:"P1",
      id:"price_1MapuELjEwGMVpD1CgGkiMgC",
      price:0,
      additionalBot:0,
      backupNum:0,
      additionalUsers:1,
      additionalWA:1,
      Email2WA:0,
    },
    {
      name:"P2",
      id:"price_1MbJDfLjEwGMVpD16f9PNpWB",
      price:0,
      additionalBot:0,
      backupNum:0,
      additionalUsers:1,
      additionalWA:1,
      Email2WA:0,
    },
    {
      name:"P3",
      id:"price_1MbJ4kLjEwGMVpD1VJvPQwET",
      price:0,
      additionalBot:0,
      backupNum:0,
      additionalUsers:3,
      additionalWA:1,
      Email2WA:0,
    },
    {
      name:"P4",
      id:"price_1Mb2WoLjEwGMVpD1xR1pELdt",
      price:0,
      additionalBot:0,
      backupNum:0,
      additionalUsers:5,
      additionalWA:1,
      Email2WA:0,
    },
    {
      name:"P5",
      id:"price_1Mb3RRLjEwGMVpD1uRgy70wb",
      price:0,
      additionalBot:0,
      backupNum:0,
      additionalUsers:50,
      additionalWA:5,
      Email2WA:0,
    },
    {
      name:"P6",
      id:"price_1MbJLmLjEwGMVpD1jvgAz0TN",
      price:0,
      additionalBot:0,
      backupNum:0,
      additionalUsers:50,
      additionalWA:5,
      Email2WA:0,
    },
    {
      name:"additional5Users",
      Plan4_id:"price_1MYhrRLjEwGMVpD1NNjZeZQk",
      Plan5_id:"price_1MYhrQLjEwGMVpD1opvxf123",
      Plan6_id:"price_1MYhrRLjEwGMVpD1JrhqmwoC",
      price4:0,
      price5:0,
      price6:0
    },
    {
      name:"waNumbers",
      id:"price_1MbIwVLjEwGMVpD1gY4vXzIp",
      price:0
    },
    {
      name:"bots",
      id:"price_1Mb2zpLjEwGMVpD1eQ0uCL7p",
      price:0
    },
    {
      name:"Email2WA",
      id:"price_1MbJQ9LjEwGMVpD1ehJo0GBz",
      price:0
    },
    {
      name:"Backup",
      id:"price_1MbJP4LjEwGMVpD14e5EWIxk",
      price:0
    },

  ]
