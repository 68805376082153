import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth-service';
import { LanguageService } from './language-service';

@Injectable({
  providedIn: 'root',
})
export class TrialUserGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    public langService: LanguageService,
  ) {}

  // activate only if the user is not trial
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let user = this.authService.getLoggedUser();
    
    return !Boolean(user?.customer?.isTrial);
  }
}
