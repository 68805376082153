import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpXsrfTokenExtractor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class XsrfInterceptorInterceptor implements HttpInterceptor {

  constructor(private tokenExtractor: HttpXsrfTokenExtractor) { }

  private actions: string[] = ["POST", "PUT", "DELETE"];
  private forbiddenActions: string[] = ["HEAD", "OPTIONS"];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // let token = this.tokenExtractor.getToken();
    let token = `${this.getCookie('XSRF-TOKEN')}`
    let permitted = this.findByActionName(request.method, this.actions);
    let forbidden = this.findByActionName(request.method, this.forbiddenActions);

    if (permitted !== undefined && forbidden === undefined && token !== null) {
      request = request.clone({ setHeaders: { "X-XSRF-TOKEN": token } });
    }

    return next.handle(request);
  }

  public getCookie(name: string) {
    let ca: Array<string> = document.cookie.split(';');
    // console.log();
    let caLen: number = ca.length;
    let cookieName = `${name}=`;
    let c: string;

    for (let i: number = 0; i < caLen; i += 1) {
      c = ca[i].replace(/^\s+/g, '');
      if (c.indexOf(cookieName) == 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  private findByActionName(name: string, actions: string[]): string | undefined {
    return actions.find(action => action.toLocaleLowerCase() === name.toLocaleLowerCase());
  }
}
