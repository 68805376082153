import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth-service';
import { LanguageService } from '../../services/language-service';
import Swal from 'sweetalert2';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '../../services/notification-service';

@Component({
  selector: 'app-re-login',
  templateUrl: './re-login.component.html',
  styleUrls: ['./re-login.component.scss'],
})
export class ReLoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    public langService: LanguageService,
    public dialogRef: MatDialogRef<ReLoginComponent>,
    private notifyService: NotificationService
  ) {}

  ngOnInit(): void {}

  email = new FormControl('', [Validators.required, Validators.email]);
  hidePwd = true;
  formData = {
    useremail: '',
    password: '',
  };

  reLogin() {
    this.authService
      .login(this.formData)
      .then((data) => {
        if (data) {
          // success path
          this.notifyService.showSuccess(
            this.langService.translateIt('common.auth-success')
          );
          this.dialogRef.close();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: this.langService.translateIt('login.incorrect-cred'),
          });
        }
      })
      .catch((err) => {
        console.log();
      });
  }
}
