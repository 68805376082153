import { NgModule } from "@angular/core";
import { AttachValidationService } from "./attach-validator.service";
import { CustomNumberTrim } from "./phone-numb-trim-pipe";

@NgModule({
    declarations: [
        CustomNumberTrim
    ],
    providers: [AttachValidationService],
    exports: [CustomNumberTrim]
})
export class CommonServicesModule { }
