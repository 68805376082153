import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth-service';
import { LanguageService } from '../../services/language-service';
import { NotificationService } from '../../services/notification-service';
import { UserDataService } from '../../services/user-data.service';

@Component({
  selector: 'app-changeuserpassword',
  templateUrl: './changeuserpassword.component.html',
  styleUrls: ['./changeuserpassword.component.scss'],
})
export class ChangeuserpasswordComponent implements OnInit {
  constructor(
    public langService: LanguageService,
    private authService: AuthService,
    private userDataService: UserDataService,
    private notifyService: NotificationService,
    public dialogRef: MatDialogRef<ChangeuserpasswordComponent>
  ) {
    this.user = this.authService.getLoggedUser();
  }

  ngOnInit(): void { }

  user;
  hidePwd = true;
  formData = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  changePwd() {
    if (this.formData.currentPassword.length > 0) {
      if (!this.isPwdMatching()) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: this.langService.translateIt('changePwd.alert-re-check'),
        });
        return;
      }

      this.userDataService.changePwd(this.user.userEmail ,this.formData.currentPassword, this.formData.newPassword).then((data) => {
        this.notifyService.showSuccess('');
        this.dialogRef.close();
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: this.langService.translateIt('changePwd.alert-empty-cur-pwd'),
      });
    }
  }

  isPwdMatching() {
    return (
      this.formData.newPassword.length > 0 &&
      this.formData.newPassword === this.formData.confirmNewPassword
    );
  }
}
