import { Injectable } from '@angular/core';

@Injectable()
export class AppStorage {
  constructor() {}

  save(itemName: string, itemContent: any) {
    localStorage.setItem(itemName, JSON.stringify(itemContent));
  }

  get(itemName: string) {
    var temp = localStorage.getItem(itemName);
    if (temp) {
      return JSON.parse(temp);
    }
    return temp;
  }

  remove(itemName: string) {
    localStorage.removeItem(itemName);
  }

  clearAll() {
    localStorage.clear();
  }
}
