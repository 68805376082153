import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { APP_CONSTANTS } from './app-constants';
import { AppStorage } from './local-storage';

@Injectable()
export class LanguageService {
  constructor(
    private translate: TranslateService,
    private appStorage: AppStorage,
  ) {
    this.init();
  }

  init() {
    var lang = this.appStorage.get(APP_CONSTANTS.LANGUAGE.SELECTED_LANGUAGE);
    lang = lang ? lang : 'en'
    this.setLanguage(lang);
    this.changeBrowserDirection(lang)
    // if (lang) {
    // }
  }

  changeBrowserDirection(lang){
    if(lang == 'ar' ){
      if(document.body.classList.contains('direction-en')) document.body.classList.remove("direction-en")
      document.body.classList.add("direction-ar")
      return 'rtl'
    }else{
      if(document.body.classList.contains('direction-ar')) document.body.classList.remove("direction-ar")
      document.body.classList.add("direction-en")
      return 'ltr'
    }
  }

  getCurrentLanguage(): string {
    return this.translate.currentLang;
  }

  setLanguage(language: string): void {
    this.translate.use(language);
    this.appStorage.save(APP_CONSTANTS.LANGUAGE.SELECTED_LANGUAGE, language);
    this.changeBrowserDirection(language)
  }

  translateIt(key: string) {
    return this.translate.instant(key);
  }

  translateItWithParam(key: string, param: any) {
    return this.translate.instant(key, param);
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
