import { Injectable } from '@angular/core';
import { ApiService } from './api-service';

@Injectable()
export class SettingsDataService {
    constructor(private apiService: ApiService) { }

    //   const
    private URL_OBJ: string = 'api/Settings';

    getAll(customerId): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + '/GetSetting?customerId=' + customerId)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    createSettings(settingsBody): Promise<any> {
        return this.apiService
            .post(this.URL_OBJ + '/CreateSetting', settingsBody)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    createPlan(settingsBody): Promise<any> {
        return this.apiService
            .post(this.URL_OBJ + '/CreatePlan', settingsBody)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    getPlan(customerId): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + '/GetPlanInfo?CustomerId=' + customerId)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    createBillingInfo(settingsBody): Promise<any> {
        return this.apiService
            .post(this.URL_OBJ + '/CreateBillingInfo', settingsBody)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    getBillingInfo(customerId): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + '/GetBllinginfo?CustomerId=' + customerId)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    createMulSettings(settingsBody): Promise<any> {
        return this.apiService
            .post(this.URL_OBJ + '/CreateMulSetting', settingsBody)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    GetLatestFERelease(): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + '/GetLatestRelease')
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }
    AddStripeInfo(settingsBody): Promise<any> {
      return this.apiService
          .post(this.URL_OBJ + '/AddStripeInfo', settingsBody)
          .toPromise()
          .then((data) => {
              return Promise.resolve(data);
          })
          .catch((error) => {
              return Promise.reject(error);
          });
  }
  GetLastStripBill(cusID): Promise<any> {
    return this.apiService
        .get(this.URL_OBJ + `/GetLastStripBill?CustomerId=${cusID}`)
        .toPromise()
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch((error) => {
            if (error.status == 404) {
                return Promise.resolve([]);
            }
            return Promise.reject(error);
        });
}
}
