import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditprofileComponent } from './editprofile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { AngularMaterialModule } from 'src/app/angular-material.module';
import { CommonServicesModule } from '../../services/common-services.module';
import { WaitingSpinnerModule } from '../waiting-spinner/waiting-spinner.module';



@NgModule({
  declarations: [EditprofileComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonServicesModule,
    WaitingSpinnerModule,
    MatIconModule,
  ]
})
export class EditprofileModule { }
