<router-outlet *ngIf="isExpress"></router-outlet>

<mat-toolbar
  color="primary"
  class="m-toolbar"
  *ngIf="headerSidebarService.showHeader && !isExpress"
>
  <button
    mat-icon-button
    type="button"
    class="toggleBtn"
    color="primary"
    (click)="sidenavToggle()"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <div class="d-flex justify-content-sm-start">
    <div style="width: 6em">
      <img class="user-box-top-logo" src="assets/img/CommSwift logo.png" />
    </div>
  </div>

  <div class="tool-bar d-flex justify-content-end">
    <div>
      <button
        mat-icon-button
        class="refresh-btn"
        *ngIf="refresherService.isVisible()"
        (click)="refreshData()"
      >
        <mat-icon>refresh</mat-icon>
      </button>
      <label class="refresh-txt" *ngIf="refresherService.isVisible()">{{
        refresherService.getLastRefreshTime()
      }}</label>
    </div>
    <div class="refresh-txt language">
      <img
        class="icon no-margin"
        src="assets/img/icon-pack/language-icon.png"
      />
      <mat-select
        (ngModelChange)="selectLanguage(lang.value)"
        id="lang"
        #lang
        [(ngModel)]="language"
      >
        <mat-option value="ar">
          <img class="icon" src="assets/img/icon-pack/arabic.png" />
          <span translate> nav.arabicLanguage </span>
        </mat-option>
        <mat-option value="en">
          <img class="icon" src="assets/img/icon-pack/english.png" />
          <span translate> nav.englishLanguage </span>
        </mat-option>
      </mat-select>
    </div>
    <!-- <div class="mx-3">
      <button class="notification-btn" mat-icon-button matBadge="15" matBadgeColor="warn" (click)="hello()">
        <mat-icon>notifications_none</mat-icon>
      </button>
    </div> -->
    <div>
      <app-user></app-user>
    </div>
  </div>
</mat-toolbar>

<mat-sidenav-container class="m-sidenav-container" *ngIf="!isExpress">
  <mat-sidenav
    [ngClass]="{
      'm-sidenav': true,
      'm-sidenav-small': headerSidebarService.sidenavSmall
    }"
    mode="side"
    fixedInViewport="true"
    opened
    #sidenav
    *ngIf="headerSidebarService.showSideBar"
  >
    <div
      class="d-flex justify-content-between flex-column pb-3"
      style="height: 90.7vh; overflow: auto"
    >
      <mat-nav-list>
        <ng-container>
          <!-- Hides the auto highlighting of the first item in the nav list -->
          <a mat-list-item routerLink="/" style="height: 0"></a>

          <a
            mat-list-item
            routerLink="dashboard"
            routerLinkActive="list-item-active"
            class="menu-button"
            [hidden]="!authService.isUserAdmin()"
          >
            <!-- <mat-icon>home</mat-icon> -->
            <img
              class="icon"
              src="assets/img/icon-pack/icon-mm-dashboard.svg"
            />
            <span translate>nav.dashboard</span>
          </a>
          <a
            mat-list-item
            routerLink="messages"
            routerLinkActive="list-item-active"
            class="menu-button"
          >
            <!-- <mat-icon>message</mat-icon> -->
            <img class="icon" src="assets/img/icon-pack/icon-mm-message.svg" />
            <span translate>nav.messages</span>
          </a>
          <a
            mat-list-item
            routerLink="chats"
            routerLinkActive="list-item-active"
            class="menu-button"
          >
            <!-- <mat-icon>message</mat-icon> -->
            <img class="icon" src="assets/img/icon-pack/icon-mm-chats.svg" />
            <span translate>nav.chats</span
            ><span class="unread-count"> ({{ unReadMessageCount }})</span>
          </a>
          <a
            mat-list-item
            routerLink="campaigns"
            routerLinkActive="list-item-active"
            class="menu-button"
          >
            <!-- <mat-icon>info</mat-icon> -->
            <img
              class="icon"
              src="assets/img/icon-pack/icon-mm-campaignes.svg"
            />
            <span translate>nav.campaigns</span>
          </a>
          <a
            mat-list-item
            routerLink="anniversary"
            routerLinkActive="list-item-active"
            class="menu-button"
          >
            <img
              class="icon"
              src="assets/img/icon-pack/anniversary_icon.svg"
            />
            <span translate>nav.anniversary</span>
          </a>
          <a
            mat-list-item
            routerLink="template"
            routerLinkActive="list-item-active"
            class="menu-button"
          >
            <!-- <mat-icon>info</mat-icon> -->
            <img
              class="icon-black icon"
              src="assets/img/icon-pack/template_icon.svg"
            />
            <span translate>nav.template</span>
          </a>
          <a
            mat-list-item
            routerLink="contacts"
            routerLinkActive="list-item-active"
            class="menu-button"
          >
            <!-- <mat-icon>info</mat-icon> -->
            <img class="icon" src="assets/img/icon-pack/icon-mm-contacts.svg" />
            <span translate>nav.contacts</span>
          </a>
          <a
            mat-list-item
            routerLink="users"
            routerLinkActive="list-item-active"
            class="menu-button"
            [hidden]="!authService.isUserAdmin()"
          >
            <!-- <mat-icon>person</mat-icon> -->
            <img class="icon" src="assets/img/icon-pack/icon-mm-users.svg" />
            <span translate>nav.users</span>
          </a>
          <a
            mat-list-item
            routerLink="devices"
            routerLinkActive="list-item-active"
            class="menu-button"
            [hidden]="!authService.isUserAdmin()"
          >
            <!-- <mat-icon>info</mat-icon> -->
            <img
              class="icon p-2"
              src="assets/img/icon-pack/icon-mm-device.svg"
            />
            <span translate>nav.devices</span>
          </a>
          <a
            mat-list-item
            routerLink="botrules"
            routerLinkActive="list-item-active"
            class="menu-button"
            [hidden]="!authService.isUserAdmin()"
          >
            <!-- <mat-icon>info</mat-icon> -->
            <img class="icon" src="assets/img/automatios-icon-1.svg" />
            <span translate>nav.botrules</span>
          </a>
          <a
            mat-list-item
            routerLink="ai-bot"
            routerLinkActive="list-item-active"
            class="menu-button"
            [hidden]="!authService.isUserAdmin() || authService.getLoggedUser()?.customer?.isTrial"
          >
            <!-- <mat-icon>info</mat-icon> -->
            <img
              class="icon"
              src="assets/img/icon-pack/icon-mm-bot-rules.svg"
            />
            <span translate>AI Bot</span>
          </a>
          <!-- <a mat-list-item routerLink="backup" routerLinkActive="list-item-active" class="menu-button"
                   hidden="true">
                   <img class="icon" src="assets/img/icon-pack/icon-backup.png"/>
                   <span translate>nav.backup</span>
                </a>  -->
          <!-- <mat-icon>info</mat-icon> -->
          <mat-divider></mat-divider>
          <a
            mat-list-item
            routerLink="settings"
            routerLinkActive="list-item-active"
            class="menu-button"
            [hidden]="!authService.isUserAdmin()"
          >
            <!-- <mat-icon>settings</mat-icon> -->
            <!-- <img class="icon" src="assets/img/icon-pack/icon-mm-settings.svg" /> -->
            <mat-icon class="icon">payment</mat-icon>
            <span translate>nav.billing</span>
          </a>
          <a
            [href]="videoUrl"
            class="menu-button"
            mat-list-item
            routerLinkActive="list-item-active"
            target="_blank"
          >
            <img class="icon" src="assets/img/icon-pack/icon-mm-videos.svg" />
            <span translate>nav.videos</span>
          </a>
          <a
            [href]="notesUrl"
            class="menu-button"
            mat-list-item
            routerLinkActive="list-item-active"
            target="_blank"
          >
            <img class="icon" src="assets/img/icon-pack/notes-icon.svg" />
            <span translate>nav.notes</span>
          </a>
        </ng-container>
      </mat-nav-list>

      <div class="footer side-nav-footer w-100">
        <mat-divider></mat-divider>
        <a
          href="https://wa.me/97124012666?text={{ 'nav.help' | translate }}"
          style="
            padding: 0 25px;
            text-decoration: none;
            font-size: 16px;
            color: #525252;
            height: 48px;
          "
          class="d-flex align-items-center mb-2"
          target="_blank"
        >
          <img
            class="mx-2"
            src="assets/img/whatsapp-img.png"
            style="width: 28px !important; object-fit: cover"
          />
          <span translate>nav.help</span>
        </a>
        <div class="" style="padding: 0 33px">
          <span #vr>V 2.8.62</span>
          <span> / </span>
          <span> {{ backendApiVersion }} </span>
        </div>
      </div>
    </div>
  </mat-sidenav>

  <mat-sidenav-content
    [ngClass]="{
      'm-main': true,
      'm-main-large': headerSidebarService.sidenavSmall,
      'm-main-show-sidebar': headerSidebarService.showSideBar,
      'header-visible': headerSidebarService.showHeader,
      'm-main-show-sidebar-ar': langService.getCurrentLanguage() == 'ar'
    }"
  >
    <router-outlet *ngIf="!isIframe"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
