import { CURRENCY } from './../../constants/currency';
import { ChangeuserpasswordComponent } from './../changeuserpassword/changeuserpassword.component';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../services/auth-service';
import { NotificationService } from '../../services/notification-service';
import { UserDataService } from '../../services/user-data.service';
import * as moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { LanguageService } from '../../services/language-service';
import { CountryISO} from 'ngx-intl-tel-input-gg';
import { FormControl } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { APP_CONSTANTS } from '../../services/app-constants';

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.scss'],
})
export class EditprofileComponent implements OnInit {
  public PAGINATION_SIZE_OPTIONS = APP_CONSTANTS.PAGINATION_SIZE_OPTIONS;

  countryISO:any = CountryISO;
  countryISOArr = []
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private matDialog: MatDialog,
    private authService: AuthService,
    private userDataService: UserDataService,
    private notifyService: NotificationService,
    public langService: LanguageService,
    public dialogRef: MatDialogRef<EditprofileComponent>
  ) {

    if (this.data?.openCompanyTab) {
      setTimeout(() => {
        this.tabIndex = 1;
      }, 1000);
    }

    for(let val of Object.values(CURRENCY)){
      let v:any = val
      this.countryISOArr.push({
        country:v.name,
        code:v.code.toLowerCase(),
        currency:v.currency.toLowerCase()
      });
    }


    var tempuser = this.authService.getLoggedUser();
    if (!tempuser) return;

    this.userDataService.getUser(tempuser.userId).then(userData => {
      this.user = userData;

      if(this.user.role.toLowerCase() == 'admin'){
        userDataService.getCompanyInfo(this.user.customerId).then((companyData) =>{
          if (companyData.defaultCountryCode && companyData.currency) {
            this.countries.setValue(`${companyData.defaultCountryCode} - ${companyData.currency}`);
          }
          this.company = companyData
          if(this.data?.companyData) this.tabIndex=1
        })
      }
      this.authService.SetLoggedUser(userData);


      var timeZones = moment.tz.names();
      var offsetTmz = [];

      for (var i in timeZones) {
        offsetTmz.push({ label: "(GMT" + moment.tz(timeZones[i]).format('Z') + ") " + timeZones[i], value: moment.tz(timeZones[i]).format('Z') });
      }
      offsetTmz.sort(function (a, b) {
        return parseFloat(a.value.replace(':', '.')) - parseFloat(b.value.replace(':', '.'));
      });

      this.displayTimezones = offsetTmz;
      this.timezoneList = offsetTmz;

      // set preferences
      var prefJson = JSON.parse(this.user.userProfileJson)
      if (prefJson) {
        this.itemsPerPage = prefJson.ItemsPerPage ? prefJson.ItemsPerPage.toString() : 10 + "";
      }
    })
  }

  user;
  company;
  timezoneList = [];
  displayTimezones = [];
  itemsPerPage;
  tabIndex=0;
  currencies = new FormControl('');
  countries = new FormControl('');
  filteredCountries: Observable<string[]>;
  filteredCurrencies: Observable<string[]>;

  ngOnInit(): void {

    this.filteredCountries = this.countries.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '',this.countryISOArr)),
    );

   }

  private _filter(value: string,arr): string[] {
    const filterValue = value.toLowerCase();

    return arr.filter(option => {
      return `${option.country} - ${option.code} - ${option.currency}`.toLowerCase().includes(filterValue)
    });
  }

  submitUser() { }

  changePass() {
    this.matDialog.open(ChangeuserpasswordComponent, this.getDialogConfig());
  }

  getDialogConfig() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'edit-pwdmodal-component';
    dialogConfig.height = '650px';
    dialogConfig.width = '650px';
    dialogConfig.direction = this.langService.changeBrowserDirection( this.langService.getCurrentLanguage() )
    return dialogConfig;
  }

  onKey(value) {
    this.displayTimezones = this.search(value.value);
  }

  search(value: string) {
    let filter = value.toLowerCase();
    return this.timezoneList.filter((option) =>
      option.label.toLowerCase().includes(filter)
    );
  }

  saveChanges() {
    this.user.mobile = this.user.officeTel;

    var prom1 = this.userDataService.editUser(this.user);
    var prom2 = this.userDataService.updatePref(this.user.userId, { itemsPerPage: Number(this.itemsPerPage) });

    Promise.all([prom1, prom2]).then(() => {
      this.notifyService.showSuccess('');
      this.userDataService.getUser(this.user.userId).then(data => {
        this.authService.SetLoggedUser(data);
        this.dialogRef.close();
      });
    });
  }

  saveChangesForCompany(){
    let country = this.countries.value.split(' - ')
    this.company.defaultCountryCode = (country[0] && country[1]) ? `${country[0]} - ${country[1]}` : '';
    this.company.currncy = country[2] || '';
    if( !this.isValidCompany(this.company) ) return;

    this.userDataService.editCompanyInfo(this.company).then(()=>{
      this.notifyService.showSuccess('');


      this.userDataService
      .getUserByEmail(
        this.authService.getLoggedUser().userEmail
      )
      .then((resUserData) => {
        this.authService.SetLoggedUser(resUserData);
      })

      this.userDataService.getCompanyInfo(this.company.customerId).then((data)=>{

        this.dialogRef.close();
      })
    })
  }

  isValidCompany(createData) {
    var validState: boolean = true;
    var invalidFields: Array<string> = [];

    if (!createData) { validState = false }

    let exist = false;
    for(let c of Object.values(CURRENCY)){
      if (createData.defaultCountryCode?.toLowerCase() === `${c.name} - ${c.code}`.toLowerCase()) {
        exist = true;
        break;
      }
    }
    if(!exist){
      validState = false;
      invalidFields.push(this.langService.translateIt('userEdit.defaultCountryCode'));
    }


    if (!createData.organisationName || !(createData.organisationName.length > 0)) {
      invalidFields.push(this.langService.translateIt('userEdit.companyName'));
      validState = false
    } else  {
      const companyNameRegex = new RegExp(/^[^@]*$/);
      if (!companyNameRegex.test(createData.organisationName) ) {
      invalidFields.push(this.langService.translateIt('userEdit.companyName'));
      validState = false
      }
    }

    if (!createData.contactName || !(createData.contactName.length > 0)) {
      invalidFields.push(this.langService.translateIt('userEdit.contactName'));
      validState = false
    } else  {
      const contactNameRegex = new RegExp(/^[a-zA-z ]+$/);
      if (!contactNameRegex.test(createData.contactName) ) {
      invalidFields.push(this.langService.translateIt('userEdit.contactName'));
      validState = false
      }
    }

    if (!createData.contactMobile || !(createData.contactMobile.length > 0) ) {
      invalidFields.push(this.langService.translateIt('userEdit.contactMobile'));
      validState = false
    } else{
      const mobileRegex = new RegExp(/^[0-9]{6,16}$/);
      if (!mobileRegex.test(createData.contactMobile) ) {
      invalidFields.push(this.langService.translateIt('userEdit.contactMobile'));
      validState = false
      }
    }

    if (!createData.contactEmail) {
      invalidFields.push(this.langService.translateIt('userEdit.email'));
      validState = false
    } else {
      const emailRegex = new RegExp('^[^@\\s]+@[^@\\s]+\\.[^@\\s]+$', 'i');
      if (!emailRegex.test(createData.contactEmail)) {
        invalidFields.push(this.langService.translateIt('userEdit.email'));
        validState = false
      }
    }

    if (!validState) {
      Swal.fire({
        icon: 'error',
        title: this.langService.translateIt('createCmpg.validationErrTxt'),
        text: invalidFields.concat().toString(),
      })
    }

    return validState;
  }
}
