import { Injectable } from '@angular/core';
import { APP_CONSTANTS } from './app-constants';
import { LanguageService } from './language-service';
import { NotificationService } from './notification-service';
import { AuthService } from './auth-service';
import { AppSessionService } from './session-storage.service';

@Injectable({
    providedIn: 'root',
})
export class AttachValidationService {
    constructor(
        public langService: LanguageService,
        private notifyService: NotificationService,
        private authService: AuthService,
        private appSessionService: AppSessionService
    ) { }

    async isValid(attachmentFile): Promise<boolean> {
        const FILE_SIZE_LIMIT = await this.getMaxSuportedSize();
        const attachedFileSize = attachmentFile.size / 1000 / 1000;
        if (attachedFileSize < FILE_SIZE_LIMIT) {
            return Promise.resolve(true);
        }

        this.showFileSizeWarning(FILE_SIZE_LIMIT);
        return Promise.resolve(false);
    }

    isOfValidFileType(attachmentFile, validFileTypesArr): boolean {
        const isValid = validFileTypesArr.includes(attachmentFile.type);
        if (!isValid) {
            this.showInvalidFileTypeWarning();
        }
        return isValid;
    }

    private isMediaFileType(attachmentFile) {
        var file_type = attachmentFile.type.split('/')[0];
        return MEDIA_FILES.find((f) => f == file_type.toLowerCase());
    }

    private showFileSizeWarning(_warningFileSize) {
        this.notifyService.showWarning(
            this.langService.translateItWithParam('common.file-size-limit', {
                size: _warningFileSize,
            })
        );
    }

    private showJFIF_FileWarning() {
        this.notifyService.showError(
            this.langService.translateIt('common.JFIF-ext')
        );
    }

    private showInvalidFileTypeWarning() {
        this.notifyService.showWarning(
            this.langService.translateIt('common.INVALID_FILE_TYPE')
        );
    }

    isVideo(attachedFile){
        return attachedFile && attachedFile.substring(attachedFile.lastIndexOf('.') +1) == 'mp4'? true: false
    }

    isImage(attachedFile){
      if(!attachedFile.includes("?")){
        var img = attachedFile && attachedFile.substring(attachedFile.lastIndexOf('.') +1)
        return img && IMAGE_EXTENSIONS.find((f) => f==img.toLowerCase()) ? true : false
      }else{
        return attachedFile && IMAGE_EXTENSIONS.find((f) => attachedFile.toLowerCase().includes('.'+f)) ? true : false
      }

    }

    isCommonExt(attachedFile){
        var ext = attachedFile && attachedFile.substring(attachedFile.lastIndexOf('.') +1).toLowerCase()
        // console.log()
        if(ext == 'pdf') return 'assets/img/pdf.png'
        if(ext == 'ppt' || ext == 'pptx') return 'assets/img/ppt-ext.png'
        if(ext == 'doc' || ext == 'docx') return 'assets/img/word-ext.png'
        if(ext == 'xlsx' || ext == 'xlsm') return 'assets/img/excel-ext.png'
        return 'assets/img/download.svg'
    }

    showName(attachedFile){
      if(!attachedFile.includes('?')){
      var file = attachedFile.substring(attachedFile.lastIndexOf('/')+1, attachedFile.lastIndexOf('.')).replace('%20',' ')
        // console.log()
        // var name = file.length <= 10 ? file : file.substring(0,10) + '*' // removing trim for now
        var name = decodeURIComponent(file).replace(/\+/g, ' '); // replace + with a space
        var ext = attachedFile.substring(attachedFile.lastIndexOf('.') )
        var fileName = name + ext
        return fileName
      }else{
        var file = attachedFile.substring(attachedFile.lastIndexOf('/')+1, attachedFile.lastIndexOf('?')).replace('%20',' ')
        return file
      }

    }

    async getMaxSuportedSize() {
        let max_file_size = this.appSessionService.get(APP_CONSTANTS.STORAGE_LABELS.MAX_FILE_SIZE);
        if (!max_file_size) {
            let response = await this.authService.getMaxFileSize();
            if (response && response.sizeInBytes) {
                max_file_size = response.sizeInBytes / 1000 / 1000;
                this.appSessionService.set('MAX_FILE_SIZE', max_file_size);
            }
        }
        return max_file_size;
    }
}

// file types considered as media files
const MEDIA_FILES = ['image', 'audio', 'video'];
const IMAGE_EXTENSIONS = ['apng', 'avif', 'gif', 'jpeg', 'png', 'svg+xml', 'webp', 'jfif', 'jpg', 'pjpeg','pjp', 'svg']
