export const APP_CONSTANTS = {
  LANGUAGE: {
    SELECTED_LANGUAGE: 'SELECTED_LANGUAGE',
    ENGLISH_LANGUAGE: 'en',
    ARABIC_LANGUAGE: 'ar',
  },

  STORAGE_LABELS: {
    USER: 'USER',
    USER_TOKEN: 'USER_TOKEN',
    REMEMBER_USER: 'REMEMBER_USER',
    MAX_FILE_SIZE: 'MAX_FILE_SIZE'
  },

  UPLOAD_FILE_SIZE_LIMIT: {
    MEDIA_FILES: {
      value: '16000000',
      label: "16Mb"
    },
    DOC_FILES: {
      value: '100000000',
      label: "100Mb"
    }
  },

  SETTINGS_KEYS: {
    // MULTIPLE_WA: "MULTIPLE_WA",
    ENABLE_API: "ENABLE_API",
    BOTS: "BOTS",
    BACKUP: "BACKUP",
    DEVICES: "DEVICES",
    USERS: "USERS",
    UNSUB_FOOTER: "UNSUB_FOOTER",
    EMAIL2WA: "EMAIL2WA"
  },

  PAGINATION_SIZE_OPTIONS: [10, 20, 50, 100, 500],

  TRAINING_VIDEO_URL: "https://www.youtube.com/playlist?list=PLM-srE9NhjBH0SScJjHtQE5riuNqGkVL4",
  NOTES_URL: "https://iamsafe.sharepoint.com/:w:/s/CSMarketingandSales/Ef_RNaH6wgVDszwsDFmKywwBVj14ho2wPwaCxSCrt5bUuw?e=eTeYqh",
  API_DOC : "https://iamsafe.sharepoint.com/:w:/s/CommSwiftTechnical/ETQLi2L_ejRFgVSfTQTAz5UBblsUSnR0q_fUGOE2dfsWfg?rtime=WkZb6TrB2kg"

};
