import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable()
export class HeaderSidebarService {
  constructor(private router: Router) {
    this.routerSubscribe = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // hide in login
        if (val.url && (val.url.startsWith('/qr'))) {
          this.hide();
        } else {
          this.show();
        }
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscribe.unsubscribe();
  
  }

  routerSubscribe: Subscription;

  showHeader: boolean = false;
  showSideBar: boolean = false;

  sidenavSmall: boolean = false;

  hide() {
    this.showHeader = false;
    this.showSideBar = false;
  }

  show() {
    this.showHeader = true;
    this.showSideBar = true;
  }
}
