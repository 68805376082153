import { EditprofileModule } from './common/components/editprofile/editprofile.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ChatMsgDataService } from 'src/app/common/services/chat-data-services';
import { CommonServicesModule } from './common/services/common-services.module';
import { NgModule, Renderer2, RendererFactory2 } from '@angular/core';
import { BrowserModule, ɵDomRendererFactory2 } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppStorage } from './common/services/local-storage';
import { ApiService } from './common/services/api-service';
import { HttpClient, HttpClientModule, HttpHandler, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './common/services/auth-service';
import { AngularMaterialModule } from './angular-material.module';
import { HeaderSidebarService } from './common/services/header-sidebar-service';
import { UserComponent } from './common/components/user/user.component';
import { EditprofileComponent } from './common/components/editprofile/editprofile.component';
import { ChangeuserpasswordComponent } from './common/components/changeuserpassword/changeuserpassword.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { HttpLoaderFactory, LanguageService } from './common/services/language-service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ReLoginComponent } from './common/components/re-login/re-login.component';
import { ToastrModule } from 'ngx-toastr';
import { NotificationService } from './common/services/notification-service';
import { AddTagsComponent } from './common/components/add-tags/add-tags.component';
import { NgxColorsModule } from 'ngx-colors';
import { RefresherService } from './common/services/refresher-service';
import { UserDataService } from './common/services/user-data.service';

import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';

import { msalConfig } from './auth-config';
import { UserMsgCounterService } from './common/services/user-msg-counter.service';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { CustomMatPaginatorIntl } from './common/services/custom-mat-paginator-intl';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { BotRulesDataService } from './common/services/bot-rules-data-service';
import { XsrfInterceptorInterceptor } from './common/services/xsrf-interceptor.interceptor';
import { SettingsDataService } from './common/services/settings-data-service';
import { DeleteFbTemplateComponent } from './components/Template/delete-fb-template/delete-fb-template.component';
import { AppSessionService } from './common/services/session-storage.service';
// import { MatLinkPreviewModule } from '@angular-material-extensions/link-preview';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
 export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    ChangeuserpasswordComponent,
    ReLoginComponent,
    AddTagsComponent,
    DeleteFbTemplateComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularMaterialModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordStrengthMeterModule.forRoot(),
    // MatLinkPreviewModule.forRoot(),
    NgxColorsModule,
    CommonServicesModule,
    PowerBIEmbedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot(),
    EditprofileModule
    // MsalModule
  ],
  providers: [
    AppStorage,
    AppSessionService,
    ApiService,
    AuthService,
    HeaderSidebarService,
    LanguageService,
    NotificationService,
    RefresherService,
    UserDataService,
    UserMsgCounterService,
    ChatMsgDataService,
    BotRulesDataService,
    SettingsDataService,
    {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory
  },
  {
    provide: MatPaginatorIntl,
    useClass: CustomMatPaginatorIntl
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: XsrfInterceptorInterceptor,
    multi: true
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService
],
  bootstrap: [AppComponent,MsalRedirectComponent]
})
export class AppModule { }
