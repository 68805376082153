import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api-service';
import { Subject } from 'rxjs';

@Injectable()
export class ChatMsgDataService {
    private messageCountSource = new Subject<void>();
    messageCount$ = this.messageCountSource.asObservable();
    constructor(private apiService: ApiService) { }

    //   const
    private URL_OBJ: string = 'api/MessageChats';

/*
    getMyInbox(userId): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + "?UserId=" + userId)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    getOutbox(userId): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + "/getOuboxItemsList?UserId=" + userId)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    getSent(userId): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + "/getSentItemsList?UserId=" + userId)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }
    getAllInboxItems(customerId): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + "/getAllInboxItems?CustomerId=" + customerId)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    getAllOutboxItemsList(customerId): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + "/getALLOuboxItemsList?CustomerId=" + customerId)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    getAllSentItemsList(customerId): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + "/getAllSentItemsList?CustomerId=" + customerId)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    getAllUndeliverdItemsList(customerId): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + "/getAllUndeliverdItemsList?CusomerId=" + customerId)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

*/
    GetIncommingMsgList_pgCustomer(body): Promise<any> {
        return this.apiService
            .post(this.URL_OBJ + "/GetIncommingMsgList_pgCustomer", body)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    GetSentMsgList_pgCustomer(body): Promise<any> {
        return this.apiService
            .post(this.URL_OBJ + "/GetSentMsgList_pgCustomer", body)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    GetUndeliveredMsgList_pgCustomer(body): Promise<any> {
        return this.apiService
            .post(this.URL_OBJ + "/GetUndeliveredMsgList_pgCustomer", body)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    GetOutboxMsgList_pgCustomer(body): Promise<any> {
        return this.apiService
            .post(this.URL_OBJ + "/GetOutboxMsgList_pgCustomer", body)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    GetIncommingMsgList_pg(body): Promise<any> {
        return this.apiService
            .post(this.URL_OBJ + "/GetIncommingMsgList_pg", body)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    GetSentMsgList_pg(body): Promise<any> {
        return this.apiService
            .post(this.URL_OBJ + "/GetSentMsgList_pg", body)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    GetUndeliveredMsgList_pg(body): Promise<any> {
        return this.apiService
            .post(this.URL_OBJ + "/GetUndeliveredMsgList_pg", body)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    GetOutboxMsgList_pg(body): Promise<any> {
        return this.apiService
            .post(this.URL_OBJ + "/GetOutboxMsgList_pg", body)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    getItm(id): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + "/" + id)
            .toPromise()
            .then((data) => {
                if (data && data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }
    getgroupmembers(grpID,UserId): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + `/getgroupmembers?groupId=${grpID}&UserId=${UserId}`)
            .toPromise()
            .then((data) => {
                if (data && data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    createMessage(messageBody): Promise<any> {
        return this.apiService
            .post(this.URL_OBJ, messageBody)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    edit(body): Promise<any> {
        return this.apiService
            .put(this.URL_OBJ + '/' + body.msgLogId, body)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    deleteChatByNumber(userId, destNumber, sourceNumber): Promise<any> {
        return this.apiService
            .delete(this.URL_OBJ + "?SourceNumber=" + sourceNumber + "&DestinationNumber=" + destNumber + "&UserId=" + userId)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    deleteChatById(msgId): Promise<any> {
        return this.apiService
            .delete(this.URL_OBJ + "/" + msgId)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    markAsRead(userId, msgLogId): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + "/MarkMsgToRead?MsgLogId=" + msgLogId + "&UserId=" + userId)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    markMultipleAsRead(msgArray): Promise<any> {
        return this.apiService
            .post(this.URL_OBJ + "/MarkMulMsgToRead", msgArray)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    loadRightSidechathistory_pg(bodyData): Promise<any> {
      return this.apiService
          .post(this.URL_OBJ + "/loadRightSidechathistory_pg", bodyData)
          .toPromise()
          .then((data) => {
              return Promise.resolve(data);
          })
          .catch((error) => {
              return Promise.reject(error);
          });
  }

  loadGroupRightSidechathistory_pg(bodyData): Promise<any> {
    return this.apiService
        .post(this.URL_OBJ + "/loadGroupRightSidechathistory_pg", bodyData)
        .toPromise()
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch((error) => {
            return Promise.reject(error);
        });
}
    getMsgCounter(userId): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + "/getUndeliverdItemsList?UserId=" + userId)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    getUnreadMsgCount(customerId, userId=0): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + "/unreadcount/" + customerId + "?UserId=" + userId)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    // getChatLeftSide(userId): Promise<any> {
    //     return this.apiService
    //         .get(this.URL_OBJ + "/GetChatLeftSideList?UserId=" + userId)
    //         .toPromise()
    //         .then((data) => {
    //             if (data) {
    //                 return Promise.resolve(data);
    //             }
    //             return Promise.reject(false);
    //         })
    //         .catch((error) => {
    //             return Promise.reject(false);
    //         });
    // }

    getAllChatLeftSideList(customerId): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + "/GetALLChatLeftSideList?CustomerId=" + customerId)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    getChatLeftSideListBySize(customerId, userId, pageNumber, pageSize): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + "/GetChatLeftSideListBySize?customerId=" + customerId + "&userId=" + userId + "&pageNumber=" + pageNumber + "&pageSize=" + pageSize)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    getChatLoadHistory(userId, destNumber, sourceNumber): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + "/loadRightSidechathistory?DestinationNumber=" + destNumber + "&SourceNumber=" + sourceNumber + "&UserId=" + userId)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    sendDeviceOTP(waNumber, waSessionId): Promise<any> {
        var bodyObj = { };
        return this.apiService
            .post(this.URL_OBJ + "/SendDeviceOTP?WANumber=" + waNumber + "&WPPSessionId=" + waSessionId, bodyObj)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    validateOTP(waSessionId, otp): Promise<any> {
        var bodyObj = { };
        return this.apiService
            .post(this.URL_OBJ + "/ValidateDeviceOTP?WPPSessionId=" + waSessionId + "&OTP=" + otp, bodyObj)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    rescheduleMsg(msgLogId, rescheduledTime: string): Promise<any> {
        var bodyObj = { msgLogId: msgLogId, reScheduleDateTime: rescheduledTime };
        return this.apiService
            .post(this.URL_OBJ + "/RescheduleMsg", bodyObj)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    uploadFile(file) {
        return this.apiService
            .upload("storage/FileUpload", file);
    }

    incrementMessageCount() {
        this.messageCountSource.next();
    }

}
