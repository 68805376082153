import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
  HttpEventType,
  HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppStorage } from './local-storage';
import { APP_CONSTANTS } from './app-constants';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReLoginComponent } from '../components/re-login/re-login.component';
import { finalize } from 'rxjs';

import { MsalService} from '@azure/msal-angular';
import { environment } from './../../../environments/environment';
import { LanguageService } from './language-service';

@Injectable()
export class ApiService {
  // private endPoint: string = 'http://localhost:3004/';
  private endPoint: string = environment.endPoint;

  public static HTTP_RESPONSE_TYPES = {
    TEXT: 'text' as 'json'
  }

  constructor(private http: HttpClient, private appStorage: AppStorage, public matDialog: MatDialog, private authMsalService: MsalService, private tokenExtractor: HttpXsrfTokenExtractor, public langService: LanguageService) { }

  private getAuthToken() {
    return this.appStorage.get(APP_CONSTANTS.STORAGE_LABELS.USER_TOKEN);
  }

  auth(urlObj: string, body: any): Observable<HttpResponse<any>> {
    return this.http
      .post<any>(this.endPoint + urlObj, body, { params: body })
      .pipe(
        tap((resp: HttpResponse<any>) => {
          return resp;
        }),
        catchError(error => {
          return throwError(error);
        })
      );
  }

  post(urlObj: string, body: any, option? : any): Observable<HttpResponse<any>> {
    var headerOptions = {
      Authorization: `Bearer ${this.getAuthToken()}`,
      // 'X-XSRF-Token': this.tokenExtractor.getToken()
    }
    if(option){
      headerOptions = {...headerOptions, ...option}
    }
    return this.http
      .post<any>(this.endPoint + urlObj, body, { headers: headerOptions })
      .pipe(
        tap((resp: HttpResponse<any>) => {
          return resp;
        }),
        catchError(err => {
          return this.handleError(err);
        })
      );
  }

  get(urlObj: string, httpResponseType?): Observable<HttpResponse<any>> {
    // let token = this.tokenExtractor.getToken()
    let httpOptions = {
      headers: {
        Authorization: `Bearer ${this.getAuthToken()}`
      }
    };
    if (httpResponseType) {
      httpOptions['responseType'] = httpResponseType
    }
    return this.http
      .get<any>(this.endPoint + urlObj, httpOptions)
      .pipe(
        tap((resp: HttpResponse<any>) => {
          return resp;
        }),
        catchError(err => {
          return this.handleError(err);
        })
      );
  }

  put(urlObj: string, body: any): Observable<HttpResponse<any>> {
    var headerOptions = {
      Authorization: `Bearer ${this.getAuthToken()}`,
      // 'X-XSRF-Token': this.tokenExtractor.getToken()
    }
    return this.http
      .put<any>(this.endPoint + urlObj, body, { headers: headerOptions })
      .pipe(
        tap((resp: HttpResponse<any>) => {
          return resp;
        }),
        catchError(err => {
          return this.handleError(err);
        })
      );
  }

  delete(urlObj: string, deleteBody?): Observable<HttpResponse<any>> {
    var headerOptions = {
      Authorization: `Bearer ${this.getAuthToken()}`,
      // 'X-XSRF-Token': this.tokenExtractor.getToken()
    }

    let options = deleteBody ? { headers: headerOptions, body: deleteBody } : { headers: headerOptions }

    return this.http
      .delete<any>(this.endPoint + urlObj, options)
      .pipe(
        tap((resp: HttpResponse<any>) => {
          return resp;
        }),
        catchError(err => {
          return this.handleError(err);
        })
      );
  }

  upload(urlObj: string, file) {
    const formData = new FormData();
    formData.append("file", file);

    var headerOptions = {
      Authorization: `Bearer ${this.getAuthToken()}`,
      // 'X-XSRF-Token': this.tokenExtractor.getToken()
    }
    return this.http
      .post(this.endPoint + urlObj, formData, { headers: headerOptions, responseType: 'text', reportProgress: true, observe: 'events', })
      .pipe(
        catchError(err => {
          return this.handleError(err);
        })
      )
  }

  /**
   *
   * @param error error
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // handle re-login
      if (error && error.status && error.status === 401) {
        // this.showReLogin();
        if(window.location.href.indexOf("express") == -1){

        this.Logout();
        let emptyObs:Observable<HttpResponse<any>> = new Observable();
        return emptyObs;
        }
      }

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  private showReLogin() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = 'relogin-component';
    dialogConfig.height = '350px';
    dialogConfig.width = '600px';
    dialogConfig.direction = this.langService.changeBrowserDirection( this.langService.getCurrentLanguage() )
    this.matDialog.open(ReLoginComponent, dialogConfig);
  }

  private Logout(){
    if(window.location.href.indexOf("express") == -1){
    this.appStorage.remove(APP_CONSTANTS.STORAGE_LABELS.USER_TOKEN);
    this.appStorage.remove(APP_CONSTANTS.STORAGE_LABELS.USER);
    setTimeout(() => {
      this.authMsalService.logout();
    }, 1000);
  }
  }
}
