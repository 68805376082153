<button mat-icon-button class="close-btn" mat-dialog-close>
    <mat-icon>close</mat-icon>
</button>
<h2 translate>login.reAuth</h2>

<div class="align-center">
    <mat-form-field appearance="outline" class="custom-width">
        <mat-label translate>login.email-placeholder</mat-label>
        <input name="username" matInput placeholder="pat@example.com" [formControl]="email" required [(ngModel)]="formData.useremail" />
        <mat-error *ngIf="email.invalid">You must enter a value</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="custom-width">
        <mat-label translate>login.password-placeholder</mat-label>
        <input name="password" matInput [type]="hidePwd ? 'password' : 'text'" [(ngModel)]="formData.password" />
        <button mat-icon-button matSuffix (click)="hidePwd = !hidePwd" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePwd">
            <mat-icon>{{ hidePwd ? "visibility_off" : "visibility" }}</mat-icon>
        </button>
    </mat-form-field>

    <button type="submit" mat-raised-button color="primary" class="custom-width" id="sign-in-btn" (click)="reLogin()"
        translate>
        {{ "login.login-button" | translate }}
    </button>
</div>
