import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth-service';
import { LanguageService } from './language-service';
import { NotificationService } from './notification-service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, Observable, of, switchMap } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    public langService: LanguageService,
    private notifyService: NotificationService,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // var loggedUser = this.authService.getLoggedUser();
    // var authToken = this.authService.getAuthToken();

    // if (!loggedUser || !authToken) {
    //   this.router.navigate(['/login']);
    //   this.notifyService.showWarning(this.langService.translateIt('common.not-loggedin'));
    //   return false;
    // }
    // return true;
    return this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        switchMap(() => {
          if (this.msalService.instance.getAllAccounts().length > 0) {
            return of(true);
          }
          this.router.navigate(['/']);
          // this.notifyService.showWarning(this.langService.translateIt('common.not-loggedin'));
          return of(false);
        })
      );
  
  }
}
