import { UserDataService } from 'src/app/common/services/user-data.service';
import { AuthService } from './auth-service';
import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class UserMsgCounterService {
    eventEmitter: EventEmitter<number> = new EventEmitter<number>();
    counter = 0;

    constructor(
        private userDataService: UserDataService,
        private authService: AuthService
    ) {
        // setInterval((_) => {
        //     this.refreshCounter();
        // }, 60000);
        // this.refreshCounter();
    }

    refreshCounter() {
        const loggedUser = this.authService.getLoggedUser();
        if (loggedUser) {
            const userEmail = loggedUser.userEmail;
            this.userDataService.getUserByEmail(userEmail).then(data => {
                if (data && data.incommingUnreadMsg) {
                    this.counter = data.incommingUnreadMsg
                }
                this.eventEmitter.emit(this.counter);
            });
        }
       
    }
}
