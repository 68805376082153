import {MatPaginatorIntl} from '@angular/material/paginator';
import {Injectable} from '@angular/core';
import { LanguageService } from './language-service';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl{
    constructor(public langService: LanguageService) {
        super();  
    
        this.getAndInitTranslations();
      }
    
      getAndInitTranslations() { 
        this.itemsPerPageLabel = this.langService.translateIt('common.recordNumberPaginatorLabel');
        this.nextPageLabel = this.langService.translateIt('common.nextPagePaginatorLabel');
        this.previousPageLabel = this.langService.translateIt('common.prevPagePaginatorLabel');
        this.changes.next();
      }
    
      override getRangeLabel = (page: number, pageSize: number, length: number) =>  {
        const start = page * pageSize + 1;
        const end = (page + 1) * pageSize;
        return `${start} - ${end}`;
      }
}
