import { Injectable } from '@angular/core';
import { APP_CONSTANTS } from './app-constants';
import { AppStorage } from './local-storage';

@Injectable()
export class TextDirectionCtrlService {
  public textDirection: string;
  private appStorage = new AppStorage();

  constructor() {
    this.CheckDiriction();
  }

  public CheckDiriction(): void {
    var lang = this.appStorage.get(APP_CONSTANTS.LANGUAGE.SELECTED_LANGUAGE)
    if (lang === 'ar') {
      this.textDirection = 'rtl';
    } else {
      this.textDirection = 'ltr';
    }
  }
}
