import { Injectable } from '@angular/core';
import { ApiService } from './api-service';

@Injectable()
export class BotRulesDataService {
    constructor(private apiService: ApiService) { }

    private URL_OBJ: string = 'api/BotRules';

    getAll(customerId): Promise<any> {
        return this.apiService
            .get(this.URL_OBJ + '/getBotRules?customerId=' + customerId)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }
    getChartData(data): Promise<any> {
        return this.apiService
            .post(`api/BotRules/getBotEnquiryStats`,data)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    createBotRule(objectBody): Promise<any> {
        return this.apiService
            .post(this.URL_OBJ + '/createBotRule', objectBody)
            .toPromise()
            .then((data) => {
                if (data) {
                    return Promise.resolve(data);
                }
                return Promise.reject(false);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    }

    uploadFile(file) {
        return this.apiService
            .upload("storage/FileUpload", file);
    }

    getBotRuleById(botId) {
        return this.apiService
            .get(this.URL_OBJ + '/getBotRuleById?id=' + botId)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    updateBotRule(botId, body) {
        return this.apiService
            .put(this.URL_OBJ + '/updateBotRule?id=' + botId, body)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    deleteBotRule(botId) {
        return this.apiService
            .delete(this.URL_OBJ + '/deleteBotRule?id=' + botId)
            .toPromise()
            .then((data) => {
                return Promise.resolve(data);
            })
            .catch((error) => {
                return Promise.reject(false);
            });
    }

    updateRuleStatus(botId,status){
        return this.apiService
        .post(this.URL_OBJ + '/updateRuleStatus?BotRuleId=' + botId + '&Status='+ status,{})
        .toPromise()
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch((error) => {
            return Promise.reject(false);
        });
    }

    UpdateRulePriority(botId,priority){
        return this.apiService
        .post(this.URL_OBJ + '/UpdateRulePriority?BotRuleId=' + botId + '&Priority='+ priority,{})
        .toPromise()
        .then((data) => {
            return Promise.resolve(data);
        })
        .catch((error) => {
            return Promise.reject(false);
        });
    }
}
