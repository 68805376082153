import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { AngularMaterialModule } from "src/app/angular-material.module";
import { WaitingSpinnerComponent } from "./waiting-spinner.component";


@NgModule({
    declarations: [
        WaitingSpinnerComponent
    ],
    imports: [
        AngularMaterialModule,
        CommonModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule
    ],
    exports: [WaitingSpinnerComponent]
})
export class WaitingSpinnerModule { }
