import { Injectable } from '@angular/core';

@Injectable()
export class AppSessionService {
    constructor() {}

    set(key: string, value: any) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    get(key: string) {
        let result = sessionStorage.getItem(key);
        return result ? JSON.parse(result) : result;
    }

    clearKey(key: string) {
        sessionStorage.removeItem(key);
    }

    clearAll() {
        sessionStorage.clear();
    }
}
