import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api-service';
import { AuthService } from './auth-service';

@Injectable()
export class UserDataService {
  constructor(
    private apiService: ApiService
  ) {}

  //   const
  private URL_OBJ: string = 'api/Users';
  private URL_USER: string = 'User';
  private URL_INVITE_OBJ: string = 'api/UserInvite';
  private URL_DEVICE = 'api/Devices';
  private URL_CUSTOMER = 'api/Customers';
  private URL_API_VERSION = 'api/Version/check';
  URL_GET_USR: string = 'user/GetUserByUserEmail/';
  editUser(userBody): Promise<any> {
    return this.apiService
      .put(this.URL_OBJ + '/' + userBody.userId, userBody)
      .toPromise()
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updatePref(userId, prefBody): Promise<any> {
    return this.apiService
      .post(this.URL_OBJ + '/updateUserProfile?UserId=' + userId, prefBody)
      .toPromise()
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getUser(userId): Promise<any> {
    return this.apiService
      .get(this.URL_OBJ + "/" + userId)
      .toPromise()
      .then((data) => {
        if (data && data) {
          return Promise.resolve(data);
        }
        return Promise.reject(false);
      })
      .catch((error) => {
        return Promise.reject(false);
      });
  }

  changePwd(userEmail, curPwd, newpassword): Promise<any> {
    const data = {
      userEmail,
      newpassword
    }
    const cusURL = `user/changepassword`
    return this.apiService
      .post(cusURL, data)
      .toPromise()
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  CreateUserOnSignUp(userBody): Promise<any> {
    console.log();
    return this.apiService
      .post(this.URL_OBJ + '/CreateUserOnSignUp', userBody)
      .toPromise()
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  addDeviceInfo(objDevice): Promise<any> {
    console.log();
    return this.apiService
      .post(this.URL_DEVICE, objDevice)
      .toPromise()
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  sendInvite(userBody): Promise<any> {
    return this.apiService
      .get(
        this.URL_INVITE_OBJ +
          '/sendinvite?email=' +
          userBody.email +
          '&customerId=' +
          userBody.customerId +
          '&role=' +
          userBody.role
      )
      .toPromise()
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getUserByEmail(email): Promise<any> {
    return this.apiService
      .get(`${this.URL_GET_USR}?useremail=${email}`)
      .toPromise()
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getUsers(CustomerId): Promise<any> {
    return this.apiService
      .get(this.URL_OBJ + '/GetUsers?CustomerId=' + CustomerId)
      .toPromise()
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  getDeviceInfo(CustomerId): Promise<any> {
    return this.apiService
      .get(this.URL_DEVICE + '/getdeviceInfoByCustmer?CustomerId=' + CustomerId)
      .toPromise()
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  UpdateUserStatus(userBody): Promise<any> {
    return this.apiService
      .post(this.URL_OBJ + `/UpdateUserStatus?UserId=${userBody.UserId}&Status=${userBody.Status}&CustomerId=${userBody.CustomerId}`,null )
      .toPromise()
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  removeUser(userId): Promise<any> {
    return this.apiService
      .get(this.URL_OBJ + '/deleteUser?id=' + userId)
      .toPromise()
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(false);
      });
  }

  getAPIVersion() {
    return this.apiService
    .get(this.URL_API_VERSION)
    .toPromise()
    .then((data) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(false);
    });
  }

  getRestToken(customerId, userEmail): Promise<any> {
    var objBody = { customerId: customerId, userEmail: userEmail };
    return this.apiService
      .post(this.URL_USER + '/GetRestApiToken', objBody)
      .toPromise()
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getCompanyInfo(customerId): Promise<any> {
    return this.apiService
      .get(this.URL_CUSTOMER + "/" + customerId)
      .toPromise()
      .then((data) => {
        if (data && data) {
          return Promise.resolve(data);
        }
        return Promise.reject(false);
      })
      .catch((error) => {
        return Promise.reject(false);
      });
  }

  editCompanyInfo(userBody): Promise<any> {
    return this.apiService
      .put(this.URL_CUSTOMER + '/' + userBody.customerId, userBody)
      .toPromise()
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getIsNewUserAccount(customerId): Promise<any> {
    return this.apiService
      .get(this.URL_OBJ + "/isnewuseraccount?CustomerId=" + customerId)
      .toPromise()
      .then((data) => {
        if (typeof data == 'boolean') {
          return Promise.resolve(data);
        }
        return Promise.reject(false);
      })
      .catch((error) => {
        return Promise.reject(false);
      });
    }
}
