import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { LanguageService } from './language-service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private toastr: ToastrService, public langService: LanguageService) { }

    showSuccess(message, title?) {
        title = title ? title : this.langService.translateIt('common.banner-success');
        this.toastr.success(message, title)
    }

    showError(message, title?) {
        title = title ? title : this.langService.translateIt('common.banner-error');
        this.toastr.error(message, title)
    }

    showInfo(message, title?) {
        title = title ? title : this.langService.translateIt('common.banner-info');
        this.toastr.info(message, title)
    }

    showWarning(message, title?) {
        title = title ? title : this.langService.translateIt('common.banner-warning');
        this.toastr.warning(message, title)
    }

}