import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth-service';
import { APP_CONSTANTS } from './app-constants';
import { AppStorage } from './local-storage';

@Injectable({
  providedIn: 'root'
})
export class IsAdminGuard implements CanActivate {
  constructor(private router : Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    ) {
    const ADMIN_VAL = 'Admin';
    var usr = JSON.parse( localStorage.getItem(APP_CONSTANTS.STORAGE_LABELS.USER) );
    if(usr && usr.role && usr.role == ADMIN_VAL){
      return true 
    }
    console.log()
    this.router.navigateByUrl('/messages')
    return false
  }
  
}
