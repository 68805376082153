<button mat-icon-button class="close-btn" mat-dialog-close>
  <mat-icon>close</mat-icon>
</button>
<h1 translate>userChangePwd.header-msg</h1>

<mat-form-field appearance="outline" class="full-width">
  <mat-label translate>userChangePwd.curr-pwd-placeholder</mat-label>
  <input
    matInput
    name="password"
    [type]="hidePwd ? 'password' : 'text'"
    [(ngModel)]="formData.currentPassword"
  />
  <button
    mat-icon-button
    matSuffix
    (click)="hidePwd = !hidePwd"
    [attr.aria-label]="'Hide password'"
    [attr.aria-pressed]="hidePwd"
  >
    <mat-icon>{{ hidePwd ? "visibility_off" : "visibility" }}</mat-icon>
  </button>
</mat-form-field>

<div id="mid-div" translate>userChangePwd.password-strength</div>

<password-strength-meter
  [password]="formData.newPassword"
></password-strength-meter>

<mat-form-field appearance="outline" class="full-width">
  <mat-label translate>userChangePwd.new-pwd-placeholder</mat-label>
  <input
    matInput
    name="newPassword"
    [type]="hidePwd ? 'password' : 'text'"
    [(ngModel)]="formData.newPassword"
  />
  <button
    mat-icon-button
    matSuffix
    (click)="hidePwd = !hidePwd"
    [attr.aria-label]="'Hide password'"
    [attr.aria-pressed]="hidePwd"
  >
    <mat-icon>{{ hidePwd ? "visibility_off" : "visibility" }}</mat-icon>
  </button>
</mat-form-field>

<mat-form-field appearance="outline" class="full-width">
  <mat-label translate>userChangePwd.confirm-pwd-placeholder</mat-label>
  <input
    matInput
    name="confirmNewPassword"
    [type]="hidePwd ? 'password' : 'text'"
    [(ngModel)]="formData.confirmNewPassword"
  />
  <button
    mat-icon-button
    matSuffix
    (click)="hidePwd = !hidePwd"
    [attr.aria-label]="'Hide password'"
    [attr.aria-pressed]="hidePwd"
  >
    <mat-icon>{{ hidePwd ? "visibility_off" : "visibility" }}</mat-icon>
  </button>
</mat-form-field>

<div class="align-right">
  <button mat-raised-button color="accent" mat-dialog-close>{{ "userChangePwd.cancel-btn" | translate }}</button>
  <button mat-raised-button color="primary" class="submit-btn" (click)="changePwd()">{{ "userChangePwd.submit-btn" | translate }}</button>
</div>
